<template>
  <section class="sidebar-vue">
    <div v-if="whitelabel === 'VCGMarkets' && get_profile.state === 'Full'">
      <add-live-account-modal v-if="showAddAccountModal" @close="closeModal" />
      <add-demo-account-modal
        v-if="showAddDemoAccountModal"
        @close="closeModal"
      />
    </div>

    <!-- Off-canvas menu for mobile -->
    <div
      v-if="get_show_sidebar"
      class="sidebar md:hidden w-sidebar h-[100vh] fixed z-50 top-0 left-0 overflow-auto"
    >
      <div v-if="get_profile.address" class="md:hidden w-1/2 h-full">
        <div class="fixed inset-0 flex z-40 h-full">
          <div class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
          <div
            class="sidebar-content-wrapper relative flex-1 flex flex-col max-w-sm w-full bg-indigo-800 justify-between"
          >
            <div
              :class="checkLanguage"
              class="sidebar-close-btn absolute top-0 -mr-14 p-1"
            >
              <button
                aria-label="Close sidebar"
                class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <svg
                  class="h-5 w-5 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M6 18L18 6M6 6l12 12"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </button>
            </div>
            <div class="flex items-center flex-shrink-0 px-4 mt-3">
              <a v-if="logoName === 'Dzengi'" href="/">
                <img
                  :src="require(`../assets/Dzengi/logo-icon.png`)"
                  alt="Logo"
                  class="h-24 w-auto"
                />
              </a>
              <a v-if="logoName.includes('FXView')" href="/">
                <img
                  :src="require(`../assets/FXView-logo.svg`)"
                  alt="Logo"
                  class="md:block h-10 md:h-12 w-auto"
                />
              </a>
              <a v-if="logoName === 'Zulu'" href="/">
                <img
                  :src="require(`../assets/Zulu-logo.svg`)"
                  alt="Logo"
                  class="md:block h-10 w-auto"
                />
              </a>
              <a v-if="logoName.includes('AAAFX')" href="/">
                <img
                  :src="require(`../assets/AAAFX-logo.png`)"
                  alt="Logo"
                  class="md:block h-14 w-auto"
                />
              </a>
              <a v-if="logoName === 'PolarisMarkets'" href="/">
                <img
                  :src="require(`../assets/${logoName}-logo.png`)"
                  alt="Logo"
                  class="h-24 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'ICMAP' || logoName === 'ICMCapital'"
                href="https://www.icmcapital.com"
                target="_blank"
              >
                <img
                  :src="require(`../assets/icm-logo.svg`)"
                  alt="Logo"
                  class="h-8 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'ICMVC' || logoName === 'ICMMU'"
                href="https://www.icm.com/"
                target="_blank"
              >
                <img
                  :src="require(`../assets/icm-logo.svg`)"
                  alt="Logo"
                  class="h-8 w-auto"
                />
              </a>
              <a v-if="logoName === 'TradeCoreUK'" href="#" target="_blank">
                <img
                  :src="require(`../assets/dxtrade-logo.svg`)"
                  alt="logo"
                  class="h-8 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'TradiNext'"
                href="https://tradinext.com/"
                target="_blank"
              >
                <img
                  :src="require(`../assets/TradiNext-logo.png`)"
                  alt="logo"
                  class="h-8 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'ICMMENA'"
                href="http://www.icmmena.com"
                target="_blank"
              >
                <img
                  :src="require(`../assets/icmmena-logo.svg`)"
                  alt="Logo"
                  class="h-12 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'GCCBrokers'"
                href="https://www.gccbrokers.com/"
                target="_blank"
              >
                <img
                  :src="require(`../assets/GCCBrokers-logo.png`)"
                  alt="Logo"
                  class="h-8 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'OrbitInvest'"
                class="m-auto"
                href="http://www.orbit-invest-trade.com/"
                target="_blank"
              >
                <img
                  :src="require(`../assets/OrbitInvest-logo.png`)"
                  alt="Logo"
                  class="h-16 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'ICMTrader'"
                href="https://www.icmtrader.com/"
                target="_blank"
              >
                <img
                  :src="require(`../assets/${logoName}-logo.svg`)"
                  alt="Logo"
                  class="h-14 w-auto"
                />
              </a>
              <a
                v-if="logoName.includes('Promena')"
                class="m-auto"
                href="https://www.promenamarkets.com/en"
                target="_blank"
              >
                <img
                  :src="require(`../assets/${logoName}-logo.png`)"
                  alt="Logo"
                  class="h-8 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'ICMEU'"
                href="https://www.icmcapital.co.uk/"
                target="_blank"
              >
                <img
                  :src="require(`../assets/${logoName}-logo.png`)"
                  alt="Logo"
                  class="h-8 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'Blackstone'"
                href="https://blackstonefutures.co.za/"
                target="_blank"
              >
                <img
                  :src="require(`../assets/${logoName}-logo.png`)"
                  alt="Logo"
                  class="h-8 w-auto"
                />
              </a>
              <a
                v-if="logoName === 'OneTradingMarkets'"
                class="m-auto"
                href=""
                target="_blank"
              >
                <img
                  :src="require(`../assets/${logoName}-logo.png`)"
                  alt="Logo"
                  class="h-auto w-32"
                />
              </a>
              <a
                v-if="logoName === 'VCGMarkets'"
                class="m-auto"
                href=""
                target="_blank"
              >
                <img
                  :src="require(`../assets/${logoName}_new_black_logo.svg`)"
                  alt="Logo"
                  class="h-auto w-32"
                />
              </a>
              <a
                v-if="logoName === 'EagleGlobalMarkets'"
                class="m-auto"
                href=""
                target="_blank"
              >
                <img
                  :src="require(`../assets/${logoName}-logo.svg`)"
                  alt="Logo"
                  class="h-auto w-52"
                />
              </a>
              <a
                v-if="logoName === 'TD365' || logoName === 'TDSouthAfrica'"
                :href="
                  logoName === 'TD365'
                    ? 'https://tradedirect365.com.au/'
                    : 'https://td365.com/'
                "
                target="_blank"
              >
                <img
                  :src="require(`../assets/${logoName}-logo.png`)"
                  alt="Logo"
                  class="h-8 w-auto"
                />
              </a>
            </div>
            <nav
              class="mt-3 flex-1 text-gray-200 px-2 flex h-full flex-col mb-2"
            >
              <div v-if="get_profile.profile.type === 'IB'" class="my-3">
                <span
                  class="truncate text-white mb-5 text-xs uppercase tracking-tighter font-medium ml-2"
                >
                  {{ $t("Trading") }}
                </span>
              </div>
              <div v-if="get_profile.profile.type !== 'IB'" class="my-3">
                <span
                  class="truncate text-white mb-5 text-xs uppercase tracking-tighter font-medium ml-2"
                >
                  {{ $t("trading-accounts") }}
                </span>
              </div>

              <div
                v-if="get_profile.profile.type !== 'IB'"
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/account-summary"
                  @click="CHANGE_SHOW_SIDEBAR"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  <span v-if="whitelabel === 'Blackstone'">
                    My Trading Accounts
                  </span>
                  <span v-else>
                    {{ $t("account-summary") }}
                  </span>
                </router-link>
              </div>
              <div
                v-if="
                  whitelabel === 'VCGMarkets' && get_profile.state === 'Full'
                "
                class="flex flex-col md:hidden"
              >
                <button
                  @click="openAddAccountModal"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal rounded-md transition ease-in-out duration-150 sidebar-button"
                >
                  <svg
                    class="h-5 mr-3 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>

                  <span>Add new trading account </span>
                </button>
                <button
                  @click="openAddDemoAccountModal"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal rounded-md transition ease-in-out duration-150 md:hidden sidebar-button"
                >
                  <svg
                    class="h-5 mr-3 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>

                  <span>Add new demo account</span>
                </button>
              </div>
              <div
                v-if="
                  get_profile.profile.type !== 'IB' &&
                  whitelabel !== 'TDSouthAfrica'
                "
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  v-if="
                    get_profile.profile.type !== 'IB' &&
                    whitelabel === 'Blackstone'
                  "
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/add-new-live-account"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("add-new-live-account") }}
                </router-link>
                <router-link
                  v-if="
                    get_profile.profile.type !== 'IB' &&
                    whitelabel === 'Blackstone'
                  "
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/add-new-demo-account"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("add-new-demo-account") }}
                </router-link>
                <a
                  v-if="get_profile.state === 'Limited'"
                  class="group flex cursor-pointer items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  @click="
                    $store.commit('SET_LIMITED_STATE_UPGRADE_MODAL', true)
                  "
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  Upgrade account
                </a>
                 <!-- # BR#41 hide open-live-account from sidebar mbeng atemson 03/03/2025 - from ICMMU excluded sidebar on mobile browser -->
                <router-link
                  v-if="get_profile.state === 'Demo' && whitelabel !== 'ICMMU'"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/open-live-account"
                  @click="CHANGE_SHOW_SIDEBAR"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("open-live-account") }}
                </router-link>
              </div>
              <div
                v-if="
                  get_profile.profile.type !== 'IB' &&
                  whitelabel !== 'TD365' &&
                  whitelabel !== 'TDSouthAfrica' &&
                  whitelabel !== 'OneTradingMarkets' &&
                  whitelabel !== 'VCGMarkets' &&
                  whitelabel !== 'EagleGlobalMarkets' &&
                  whitelabel !== 'OrbitInvest' &&
                  whitelabel !== 'GCCBrokers' &&
                  whitelabel !== 'TradeCoreUK'
                "
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  v-if="whitelabel !== 'ICMEU'"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/analysis"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    height="19"
                    stroke="currentColor"
                    viewBox="0 0 20 19"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 9L8 6L11 9L15 5M6 18L10 14L14 18M1 1H19M2 1H18V13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V1Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("Analysis") }}
                </router-link>
                <a
                  v-if="whitelabel === 'Blackstone'"
                  class="cursor-pointer group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  @click="openChat()"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  Start LiveChat
                </a>
                <a
                  v-if="
                    get_profile.profile.type !== 'IB' && whitelabel === 'ICMVC'
                  "
                  :href="link"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  target="_blank"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("web-terminal") }}
                </a>
              </div>

              <!--     IB     -->
              <div
                v-if="get_profile.profile.type === 'IB'"
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/ib/summary"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 10V20V10ZM16 17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21072 4.96086 3 5.46957 3 6V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17H8H16ZM9 13L12 10L9 13ZM12 10L15 13L12 10Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("Summary") }}
                </router-link>
              </div>
              <div
                v-if="get_profile.profile.type === 'IB'"
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/ib/tracking-links"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8283 10.172C13.0782 9.42216 12.061 9.0009 11.0003 9.0009C9.93967 9.0009 8.92244 9.42216 8.17232 10.172L4.17232 14.172C3.79028 14.541 3.48556 14.9824 3.27592 15.4704C3.06628 15.9584 2.95594 16.4833 2.95132 17.0144C2.94671 17.5456 3.04791 18.0723 3.24904 18.5639C3.45016 19.0554 3.74717 19.5021 4.12274 19.8776C4.49832 20.2532 4.94492 20.5502 5.43651 20.7513C5.92809 20.9525 6.45481 21.0537 6.98593 21.049C7.51705 21.0444 8.04193 20.9341 8.52994 20.7244C9.01796 20.5148 9.45934 20.2101 9.82832 19.828L10.9303 18.727M10.1723 13.828C10.9224 14.5779 11.9397 14.9992 13.0003 14.9992C14.061 14.9992 15.0782 14.5779 15.8283 13.828L19.8283 9.82804C20.557 9.07363 20.9601 8.06322 20.951 7.01444C20.9419 5.96565 20.5212 4.9624 19.7796 4.22077C19.038 3.47913 18.0347 3.05846 16.9859 3.04935C15.9371 3.04023 14.9267 3.44341 14.1723 4.17204L13.0723 5.27204"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("tracking-links") }}
                </router-link>
              </div>
              <div
                v-if="get_profile.profile.type === 'IB'"
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/ib/client-list"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4.354C12.5374 3.7447 13.2477 3.31351 14.0362 3.11779C14.8247 2.92208 15.6542 2.97112 16.4142 3.2584C17.1741 3.54568 17.8286 4.05757 18.2905 4.72596C18.7524 5.39435 18.9998 6.18754 18.9998 7C18.9998 7.81246 18.7524 8.60565 18.2905 9.27404C17.8286 9.94243 17.1741 10.4543 16.4142 10.7416C15.6542 11.0289 14.8247 11.0779 14.0362 10.8822C13.2477 10.6865 12.5374 10.2553 12 9.646M15 21H3V20C3 18.4087 3.63214 16.8826 4.75736 15.7574C5.88258 14.6321 7.4087 14 9 14C10.5913 14 12.1174 14.6321 13.2426 15.7574C14.3679 16.8826 15 18.4087 15 20V21ZM15 21H21V20C21.0001 18.9467 20.723 17.9119 20.1965 16.9997C19.6699 16.0875 18.9125 15.3299 18.0004 14.8032C17.0882 14.2765 16.0535 13.9992 15.0002 13.9992C13.9469 13.9991 12.9122 14.2764 12 14.803M13 7C13 8.06087 12.5786 9.07828 11.8284 9.82843C11.0783 10.5786 10.0609 11 9 11C7.93913 11 6.92172 10.5786 6.17157 9.82843C5.42143 9.07828 5 8.06087 5 7C5 5.93913 5.42143 4.92172 6.17157 4.17157C6.92172 3.42143 7.93913 3 9 3C10.0609 3 11.0783 3.42143 11.8284 4.17157C12.5786 4.92172 13 5.93913 13 7Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("client-list") }}
                </router-link>
              </div>
              <div>
                <div
                  v-if="
                    (get_profile.state !== 'Demo' &&
                      get_profile.profile.type !== 'IB') ||
                    (whitelabel === 'TDSouthAfrica' &&
                      get_profile.profile.type !== 'IB')
                  "
                >
                  <div class="my-3">
                    <span
                      class="truncate text-white text-xs uppercase tracking-tighter font-medium ml-2"
                    >
                      {{ $t("Payments") }}
                    </span>
                  </div>
                  <div
                    v-if="get_profile.profile.type !== 'IB'"
                    @click="CHANGE_SHOW_SIDEBAR"
                  >
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/deposit-funds"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M10 7V17V7ZM14 14H17C17.5304 14 18.0391 13.7893 18.4142 13.4142C18.7893 13.0391 19 12.5304 19 12V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21072 1.96086 1 2.46957 1 3V12C1 12.5304 1.21071 13.0391 1.58579 13.4142C1.96086 13.7893 2.46957 14 3 14H6H14ZM7 10L10 7L7 10ZM10 7L13 10L10 7Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{ $t("deposit-funds-e645a4c2bcea65c6fccd26238e52ec6e") }}
                    </router-link>
                  </div>
                  <div
                    v-if="get_profile.profile.type !== 'IB'"
                    @click="CHANGE_SHOW_SIDEBAR"
                  >
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/withdraw-funds"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M10 11V1V11ZM6 4H3C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H17C17.5304 17 18.0391 16.7893 18.4142 16.4142C18.7893 16.0391 19 15.5304 19 15V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4H14H6ZM13 8L10 11L13 8ZM10 11L7 8L10 11Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{
                        $t("withdraw-funds-f08d90c08960cd320a338bb2571ff562")
                      }}
                    </router-link>
                  </div>
                  <div
                    v-if="
                      get_profile.profile.type !== 'IB' &&
                      whitelabel !== 'TD365' &&
                      whitelabel !== 'TDSouthAfrica' &&
                      whitelabel !== 'ICMTrader' &&
                      whitelabel !== 'OneTradingMarkets' &&
                      whitelabel !== 'VCGMarkets' &&
                      whitelabel !== 'EagleGlobalMarkets' &&
                      whitelabel !== 'OrbitInvest' &&
                      whitelabel !== 'GCCBrokers' &&
                      whitelabel !== 'TradeCoreUK' &&
                      whitelabel !== 'ICMMENA' &&
                      whitelabel !== 'ICMCapital' &&
                      whitelabel !== 'AAAFXEU' &&
                      whitelabel !== 'AAAFXGlobal' &&
                      whitelabel !== 'FXViewEU' &&
                      whitelabel !== 'FXViewGlobal'
                    "
                    @click="CHANGE_SHOW_SIDEBAR"
                  >
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      to="/apply-for-mastercard"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.8889 20H20.5556C21.2038 20 21.8256 19.7407 22.284 19.279C22.7425 18.8174 23 18.1913 23 17.5385V6.46154C23 5.8087 22.7425 5.1826 22.284 4.72097C21.8256 4.25934 21.2038 4 20.5556 4H3.44444C2.79614 4 2.17438 4.25934 1.71597 4.72097C1.25755 5.1826 1 5.8087 1 6.46154V17.5385C1 18.1913 1.25753 18.8174 1.71597 19.279C2.17438 19.7407 2.79614 20 3.44444 20H7.11111H16.8889Z"
                          opacity="0.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <circle
                          cx="9"
                          cy="12"
                          fill="white"
                          opacity="0.8"
                          r="4"
                        />
                        <circle
                          cx="15"
                          cy="12"
                          fill="white"
                          opacity="0.3"
                          r="4"
                        />
                      </svg>
                      {{ $t("apply-for-mastercard") }}
                    </router-link>
                  </div>
                  <div
                    v-if="
                      get_profile.profile.type !== 'IB' &&
                      whitelabel !== 'TDSouthAfrica' &&
                      whitelabel !== 'EagleGlobalMarkets' &&
                      whitelabel !== 'OrbitInvest' &&
                      whitelabel !== 'TradeCoreUK'
                    "
                    @click="CHANGE_SHOW_SIDEBAR"
                  >
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/internal-transfer"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                      {{
                        $t("internal-transfer-2f9b036cc57f3d906425010f8e828fb5")
                      }}
                    </router-link>
                  </div>
                  <div
                    v-if="get_profile.profile.type !== 'IB'"
                    @click="CHANGE_SHOW_SIDEBAR"
                  >
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/payment-history"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                      {{
                        $t("payment-history-91a1bd08a417cbd1ce9ee50d8c67bab6")
                      }}
                    </router-link>
                  </div>
                  <div
                    v-if="
                      get_profile.profile.type !== 'IB' &&
                      whitelabel !== 'TD365' &&
                      whitelabel !== 'TDSouthAfrica' &&
                      whitelabel !== 'EagleGlobalMarkets' &&
                      whitelabel !== 'VCGMarkets' &&
                      whitelabel !== 'OrbitInvest' &&
                      whitelabel !== 'TradeCoreUK'
                    "
                    @click="CHANGE_SHOW_SIDEBAR"
                  >
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/bank-details"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.6994 8.09466L13.2571 4.34239C12.8705 4.11794 12.4387 4.00049 12 4.00049C11.5613 4.00049 11.1295 4.11794 10.7429 4.34239L4.30057 8.09466C4.20975 8.14759 4.13386 8.22617 4.08094 8.32203C4.02802 8.4179 4.00005 8.52749 4 8.63918V9.38171C4 9.54582 4.0602 9.70321 4.16737 9.81925C4.27453 9.9353 4.41988 10.0005 4.57143 10.0005H19.4286C19.5801 10.0005 19.7255 9.9353 19.8326 9.81925C19.9398 9.70321 20 9.54582 20 9.38171V8.63918C19.9999 8.52749 19.972 8.4179 19.9191 8.32203C19.8661 8.22617 19.7902 8.14759 19.6994 8.09466Z"
                        />
                        <rect
                          height="16"
                          rx="0.75"
                          transform="rotate(90 20 17)"
                          width="1.5"
                          x="20"
                          y="17"
                        />
                        <path
                          clip-rule="evenodd"
                          d="M6.25 11.75C6.25 11.3358 6.58579 11 7 11C7.41421 11 7.75 11.3358 7.75 11.75V15.25C7.75 15.6642 7.41421 16 7 16C6.58579 16 6.25 15.6642 6.25 15.25V11.75ZM11.2498 11.75C11.2498 11.3358 11.5856 11 11.9998 11C12.414 11 12.7498 11.3358 12.7498 11.75V15.25C12.7498 15.6642 12.414 16 11.9998 16C11.5856 16 11.2498 15.6642 11.2498 15.25V11.75ZM16.9996 11C16.5854 11 16.2496 11.3358 16.2496 11.75V15.25C16.2496 15.6642 16.5854 16 16.9996 16C17.4138 16 17.7496 15.6642 17.7496 15.25V11.75C17.7496 11.3358 17.4138 11 16.9996 11Z"
                          fill-rule="evenodd"
                        />
                      </svg>
                      {{ $t("bank-details-f1b3e6404a749ae3a1cd615552d0eb0f") }}
                    </router-link>
                  </div>
                </div>

                <!--       IB      -->
                <div v-if="get_profile.profile.type === 'IB'">
                  <div class="my-3">
                    <span
                      class="truncate text-white text-xs uppercase tracking-tighter font-medium ml-2"
                    >
                      {{ $t("Payments") }}
                    </span>
                  </div>
                  <div @click="CHANGE_SHOW_SIDEBAR">
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/ib/request-payment"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 14V4V14ZM8 7H5C4.46957 7 3.96086 7.21071 3.58579 7.58579C3.21071 7.96086 3 8.46957 3 9V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H19C19.5304 20 20.0391 19.7893 20.4142 19.4142C20.7893 19.0391 21 18.5304 21 18V9C21 8.46957 20.7893 7.96086 20.4142 7.58579C20.0391 7.21071 19.5304 7 19 7H16H8ZM15 11L12 14L15 11ZM12 14L9 11L12 14Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{ $t("request-a-payment") }}
                    </router-link>
                  </div>
                  <div @click="CHANGE_SHOW_SIDEBAR">
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/ib/pending-withdrawals"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M18.75 14.9603V18C18.75 18.6896 18.5229 19.3725 18.088 19.8943C17.6498 20.4202 17.023 20.75 16.3333 20.75H4.66667C3.97702 20.75 3.35019 20.4202 2.91198 19.8943C2.47711 19.3725 2.25 18.6896 2.25 18V9C2.25 8.31041 2.47711 7.6275 2.912 7.10564C3.35021 6.57979 3.97702 6.25 4.66667 6.25H11.2205C11.0965 6.73187 11.0225 7.23383 11.0044 7.75H4.66667C4.47227 7.75 4.25124 7.84162 4.06433 8.06592C3.87409 8.2942 3.75 8.62874 3.75 9V18C3.75 18.3712 3.87407 18.7057 4.06434 18.9341C4.25124 19.1584 4.47226 19.25 4.66667 19.25H16.3333C16.5277 19.25 16.7487 19.1584 16.9357 18.9341C17.1259 18.7057 17.25 18.3712 17.25 18V14.9603C17.4964 14.9865 17.7466 15 18 15C18.2534 15 18.5036 14.9865 18.75 14.9603Z"
                          fill="white"
                          fill-rule="evenodd"
                        />
                        <path
                          d="M18 5.77778V8L19.6667 9.66667M23 8C23 8.65661 22.8707 9.30679 22.6194 9.91342C22.3681 10.52 21.9998 11.0712 21.5355 11.5355C21.0712 11.9998 20.52 12.3681 19.9134 12.6194C19.3068 12.8707 18.6566 13 18 13C17.3434 13 16.6932 12.8707 16.0866 12.6194C15.48 12.3681 14.9288 11.9998 14.4645 11.5355C14.0002 11.0712 13.6319 10.52 13.3806 9.91342C13.1293 9.30679 13 8.65661 13 8C13 6.67392 13.5268 5.40215 14.4645 4.46447C15.4021 3.52678 16.6739 3 18 3C19.3261 3 20.5979 3.52678 21.5355 4.46447C22.4732 5.40215 23 6.67392 23 8Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{ $t("pending-withdrawals") }}
                    </router-link>
                  </div>
                  <div @click="CHANGE_SHOW_SIDEBAR">
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/ib/payments-history"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 9V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V13C3 13.5304 3.21071 14.0391 3.58579 14.4142C3.96086 14.7893 4.46957 15 5 15H7M9 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V11C21 10.4696 20.7893 9.96086 20.4142 9.58579C20.0391 9.21071 19.5304 9 19 9H9C8.46957 9 7.96086 9.21071 7.58579 9.58579C7.21071 9.96086 7 10.4696 7 11V17C7 17.5304 7.21071 18.0391 7.58579 18.4142C7.96086 18.7893 8.46957 19 9 19ZM16 14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16C13.4696 16 12.9609 15.7893 12.5858 15.4142C12.2107 15.0391 12 14.5304 12 14C12 13.4696 12.2107 12.9609 12.5858 12.5858C12.9609 12.2107 13.4696 12 14 12C14.5304 12 15.0391 12.2107 15.4142 12.5858C15.7893 12.9609 16 13.4696 16 14Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{
                        $t("payment-history-91a1bd08a417cbd1ce9ee50d8c67bab6")
                      }}
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="my-3">
                <span
                  class="truncate text-white text-xs uppercase tracking-tighter font-medium ml-2"
                >
                  {{ $t("documents") }}
                </span>
              </div>

              <div
                v-if="get_profile.profile.type !== 'IB'"
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="legal-documents"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("legal-documents-13e272c98e7585c7fa2ffbc2c47f7208") }}
                </router-link>
              </div>

              <div
                v-if="
                  get_profile.profile.type !== 'IB' &&
                  get_profile.state !== 'Demo' &&
                  whitelabel !== 'TD365'
                "
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="upload-documents"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    height="20"
                    stroke="currentColor"
                    viewBox="0 0 16 20"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 8V14M8 8L5 11M8 8L11 11M13 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H8.586C8.8512 1.00006 9.10551 1.10545 9.293 1.293L14.707 6.707C14.8946 6.89449 14.9999 7.1488 15 7.414V17C15 17.5304 14.7893 18.0391 14.4142 18.4142C14.0391 18.7893 13.5304 19 13 19Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("upload-documents-7773dfbb1710dccc7b95283d157c8bc6") }}
                </router-link>
              </div>

              <div
                v-if="get_profile.profile.type === 'IB'"
                @click="CHANGE_SHOW_SIDEBAR"
              >
                <router-link
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/ib/upload-documents"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 10V16M12 10L9 13M12 10L15 13M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H12.586C12.8512 3.00006 13.1055 3.10545 13.293 3.293L18.707 8.707C18.8946 8.89449 18.9999 9.1488 19 9.414V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("upload-documents-7773dfbb1710dccc7b95283d157c8bc6") }}
                </router-link>
              </div>
              <div
                v-if="
                  whitelabel === 'VCGMarkets' && get_profile.state === 'Full'
                "
                class="w-full mt-auto flex items-center gap-2 justify-center md:hidden"
              >
                <a
                  href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5&hl=en&gl=US"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/get_google.png`)"
                    alt="google play"
                    class="w-auto"
                  />
                </a>
                <a
                  href="https://apps.apple.com/il/app/metatrader-5/id413251709"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/get_ios.png`)"
                    alt="google play"
                    class="w-auto"
                  />
                </a>
              </div>
            </nav>
            <div
              v-if="
                whitelabel !== 'TDSouthAfrica' &&
                whitelabel !== 'EagleGlobalMarkets' &&
                whitelabel !== 'OrbitInvest' &&
                whitelabel !== 'TradeCoreUK'
              "
              class="p-2"
            >
              <language-switcher></language-switcher>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div
      class="sidebar hidden md:block w-sidebar overflow-x-hidden h-screen fixed"
    >
      <div
        v-if="get_profile.address"
        class="hidden h-full min-h-screen md:flex md:flex-shrink-0 flex bg-gray-100 w-100"
      >
        <div class="flex flex-col w-full">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="flex flex-col flex-1 bg-indigo-800">
            <div class="flex-1 flex flex-col pt-5 pb-4">
              <div class="flex items-center flex-shrink-0 px-4">
                <router-link v-if="logoName === 'Dzengi'" class="m-auto" to="/">
                  <img
                    :src="require(`../assets/Dzengi/logo-icon.png`)"
                    alt="Logo"
                    class="h-24 w-auto"
                  />
                </router-link>

                <router-link
                  v-if="logoName.includes('AAAFX')"
                  class="m-auto"
                  to="/"
                >
                  <img
                    :src="require(`../assets/AAAFX-logo.png`)"
                    alt="Logo"
                    class="h-24 w-auto"
                  />
                </router-link>

                <router-link
                  v-if="logoName.includes('FXView')"
                  class="m-auto"
                  to="/"
                >
                  <img
                    :src="require(`../assets/FXView-logo.svg`)"
                    alt="Logo"
                    class="h-24 w-auto"
                  />
                </router-link>
                <router-link v-if="logoName === 'Zulu'" class="m-auto" to="/">
                  <img
                    :src="require(`../assets/Zulu-logo.svg`)"
                    alt="Logo"
                    class="h-20 w-auto"
                  />
                </router-link>

                <router-link
                  v-if="logoName === 'PolarisMarkets'"
                  class="m-auto"
                  to="/"
                >
                  <img
                    :src="require(`../assets/${logoName}-logo.png`)"
                    alt="Logo"
                    class="h-24 w-auto"
                  />
                </router-link>

                <a
                  v-if="logoName === 'ICMAP' || logoName === 'ICMCapital'"
                  class="m-auto"
                  href="https://www.icmcapital.com"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/icm-logo.svg`)"
                    alt="Logo"
                    class="h-8 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'ICMVC' || logoName === 'ICMMU'"
                  class="m-auto"
                  href="https://www.icm.com/"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/icm-logo.svg`)"
                    alt="Logo"
                    class="h-8 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'TradeCoreUK'"
                  class="m-auto"
                  href="#"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/dxtrade-logo.svg`)"
                    alt="logo"
                    class="h-8 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'TradiNext'"
                  class="m-auto"
                  href="https://tradinext.com/"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/TradiNext-logo.png`)"
                    alt="logo"
                    class="h-8 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'ICMMENA'"
                  class="m-auto"
                  href="http://www.icmmena.com"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/icmmena-logo.svg`)"
                    alt="Logo"
                    class="h-12 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'GCCBrokers'"
                  href="https://www.gccbrokers.com/"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/GCCBrokers-logo.png`)"
                    alt="Logo"
                    class="h-8 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'OrbitInvest'"
                  class="m-auto"
                  href="http://www.orbit-invest-trade.com/"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/OrbitInvest-logo.png`)"
                    alt="Logo"
                    class="h-16 w-auto"
                  />
                </a>
                <!-- <a href="https://icmcapital.co.uk/ " class="m-auto" target="_blank" v-if="logoName === 'ICMCapital'">
                  <img class="h-8 w-auto" :src="require(`../assets/icm-logo.svg`)" alt="Logo">
                </a> -->
                <a
                  v-if="logoName === 'ICMTrader'"
                  class="m-auto"
                  href="https://www.icmtrader.com/"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/${logoName}-logo.svg`)"
                    alt="Logo"
                    class="h-14 w-auto"
                  />
                </a>
                <a
                  v-if="logoName.includes('Promena')"
                  class="m-auto"
                  href="https://www.promenamarkets.com/en"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/${logoName}-logo.png`)"
                    alt="Logo"
                    class="h-8 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'ICMEU'"
                  class="m-auto"
                  href="https://www.icmcapital.co.uk/"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/${logoName}-logo.png`)"
                    alt="Logo"
                    class="h-8 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'Blackstone'"
                  class="m-auto"
                  href="https://blackstonefutures.co.za/"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/${logoName}-logo.png`)"
                    alt="Logo"
                    class="h-8 w-auto"
                  />
                </a>
                <a
                  v-if="logoName === 'OneTradingMarkets'"
                  class="m-auto"
                  href=""
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/${logoName}-logo.png`)"
                    alt="Logo"
                    class="h-auto w-32"
                  />
                </a>
                <router-link
                  v-if="logoName === 'VCGMarkets'"
                  class="m-auto"
                  to="/"
                >
                  <img
                    :src="require(`../assets/${logoName}_new_black_logo.svg`)"
                    alt="Logo"
                    class="h-auto w-32"
                  />
                </router-link>
                <a
                  v-if="logoName === 'EagleGlobalMarkets'"
                  class="m-auto"
                  href=""
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/${logoName}-logo.svg`)"
                    alt="Logo"
                    class="h-auto w-52"
                  />
                </a>
                <a
                  v-if="logoName === 'TD365' || logoName === 'TDSouthAfrica'"
                  :href="
                    logoName === 'TD365'
                      ? 'https://tradedirect365.com.au/'
                      : 'https://td365.com/'
                  "
                  class="m-auto"
                  target="_blank"
                >
                  <img
                    :src="require(`../assets/${logoName}-logo.png`)"
                    alt="Logo"
                    class="h-8 w-auto"
                  />
                </a>
              </div>
              <nav class="mt-5 flex-1 text-gray-200 px-2">
                <div v-if="get_profile.profile.type === 'IB'" class="my-3">
                  <span
                    class="truncate text-white mb-5 text-xs uppercase tracking-tighter font-medium ml-2"
                  >
                    {{ $t("Trading") }}
                  </span>
                </div>
                <div v-if="get_profile.profile.type !== 'IB'" class="my-3">
                  <span
                    class="truncate text-white mb-5 text-xs uppercase tracking-tighter font-medium ml-2"
                  >
                    {{ $t("trading-accounts") }}
                  </span>
                </div>
                <router-link
                  v-if="get_profile.profile.type !== 'IB'"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/account-summary"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  <span v-if="whitelabel === 'Blackstone'">
                    My Trading Accounts
                  </span>
                  <span v-else>
                    {{ $t("account-summary") }}
                  </span>
                </router-link>
                <div
                  v-if="
                    whitelabel === 'VCGMarkets' && get_profile.state === 'Full'
                  "
                  class="flex flex-col"
                >
                  <button
                    @click="openAddAccountModal"
                    class="group flex items-center px-2 py-2 text-sm leading-5 font-normal sidebar-button rounded-md focus:outline-none transition ease-in-out duration-150"
                  >
                    <svg
                      class="h-5 mr-3 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>

                    <span>Add new trading account </span>
                  </button>
                  <button
                    @click="openAddDemoAccountModal"
                    class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-500 sidebar-button rounded-md focus:outline-none transition ease-in-out duration-150"
                  >
                    <svg
                      class="h-5 mr-3 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </svg>

                    <span>Add new demo account</span>
                  </button>
                </div>
                <router-link
                  v-if="get_profile.profile.type === 'IB'"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/ib/summary"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 10V20V10ZM16 17H19C19.5304 17 20.0391 16.7893 20.4142 16.4142C20.7893 16.0391 21 15.5304 21 15V6C21 5.46957 20.7893 4.96086 20.4142 4.58579C20.0391 4.21071 19.5304 4 19 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21072 4.96086 3 5.46957 3 6V15C3 15.5304 3.21071 16.0391 3.58579 16.4142C3.96086 16.7893 4.46957 17 5 17H8H16ZM9 13L12 10L9 13ZM12 10L15 13L12 10Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("Summary") }}
                </router-link>
                <router-link
                  v-if="get_profile.profile.type === 'IB'"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/ib/tracking-links"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8283 10.172C13.0782 9.42216 12.061 9.0009 11.0003 9.0009C9.93967 9.0009 8.92244 9.42216 8.17232 10.172L4.17232 14.172C3.79028 14.541 3.48556 14.9824 3.27592 15.4704C3.06628 15.9584 2.95594 16.4833 2.95132 17.0144C2.94671 17.5456 3.04791 18.0723 3.24904 18.5639C3.45016 19.0554 3.74717 19.5021 4.12274 19.8776C4.49832 20.2532 4.94492 20.5502 5.43651 20.7513C5.92809 20.9525 6.45481 21.0537 6.98593 21.049C7.51705 21.0444 8.04193 20.9341 8.52994 20.7244C9.01796 20.5148 9.45934 20.2101 9.82832 19.828L10.9303 18.727M10.1723 13.828C10.9224 14.5779 11.9397 14.9992 13.0003 14.9992C14.061 14.9992 15.0782 14.5779 15.8283 13.828L19.8283 9.82804C20.557 9.07363 20.9601 8.06322 20.951 7.01444C20.9419 5.96565 20.5212 4.9624 19.7796 4.22077C19.038 3.47913 18.0347 3.05846 16.9859 3.04935C15.9371 3.04023 14.9267 3.44341 14.1723 4.17204L13.0723 5.27204"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("tracking-links") }}
                </router-link>
                <router-link
                  v-if="get_profile.profile.type === 'IB'"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/ib/client-list"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 4.354C12.5374 3.7447 13.2477 3.31351 14.0362 3.11779C14.8247 2.92208 15.6542 2.97112 16.4142 3.2584C17.1741 3.54568 17.8286 4.05757 18.2905 4.72596C18.7524 5.39435 18.9998 6.18754 18.9998 7C18.9998 7.81246 18.7524 8.60565 18.2905 9.27404C17.8286 9.94243 17.1741 10.4543 16.4142 10.7416C15.6542 11.0289 14.8247 11.0779 14.0362 10.8822C13.2477 10.6865 12.5374 10.2553 12 9.646M15 21H3V20C3 18.4087 3.63214 16.8826 4.75736 15.7574C5.88258 14.6321 7.4087 14 9 14C10.5913 14 12.1174 14.6321 13.2426 15.7574C14.3679 16.8826 15 18.4087 15 20V21ZM15 21H21V20C21.0001 18.9467 20.723 17.9119 20.1965 16.9997C19.6699 16.0875 18.9125 15.3299 18.0004 14.8032C17.0882 14.2765 16.0535 13.9992 15.0002 13.9992C13.9469 13.9991 12.9122 14.2764 12 14.803M13 7C13 8.06087 12.5786 9.07828 11.8284 9.82843C11.0783 10.5786 10.0609 11 9 11C7.93913 11 6.92172 10.5786 6.17157 9.82843C5.42143 9.07828 5 8.06087 5 7C5 5.93913 5.42143 4.92172 6.17157 4.17157C6.92172 3.42143 7.93913 3 9 3C10.0609 3 11.0783 3.42143 11.8284 4.17157C12.5786 4.92172 13 5.93913 13 7Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("client-list") }}
                </router-link>
                <router-link
                  v-if="
                    get_profile.profile.type !== 'IB' &&
                    whitelabel === 'Blackstone'
                  "
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/add-new-live-account"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("add-new-live-account") }}
                </router-link>
                <router-link
                  v-if="
                    get_profile.profile.type !== 'IB' &&
                    whitelabel === 'Blackstone'
                  "
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/add-new-demo-account"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("add-new-demo-account") }}
                </router-link>
                <a
                  v-if="
                    get_profile.state === 'Limited' &&
                    whitelabel !== 'VCGMarkets' &&
                    whitelabel !== 'FXViewGlobal' &&
                    whitelabel !== 'FXViewEU' &&
                    whitelabel !== 'AAAFXEU' &&
                    whitelabel !== 'AAAFXGlobal'
                  "
                  class="group cursor-pointer flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  @click="$store.commit('SET_ICM_PROOF_MODAL', 'Address Proof')"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  Upgrade account
                </a>
                <!-- # BR#41 hide open-live-account from sidebar mbeng atemson 09/12/2024 -->
                <router-link
                  v-if="
                    get_profile.state === 'Demo' &&
                    get_profile.profile.type !== 'IB' &&
                    whitelabel !== 'TDSouthAfrica' &&
                    whitelabel !== 'Blackstone' &&
                    whitelabel !== 'ICMMU' 
                  "
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/open-live-account"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("open-live-account") }}
                </router-link>
                <router-link
                  v-if="
                    get_profile.profile.type !== 'IB' &&
                    whitelabel !== 'ICMEU' &&
                    whitelabel !== 'TD365' &&
                    whitelabel !== 'TDSouthAfrica' &&
                    whitelabel !== 'Blackstone' &&
                    whitelabel !== 'OneTradingMarkets' &&
                    whitelabel !== 'VCGMarkets' &&
                    whitelabel !== 'EagleGlobalMarkets' &&
                    whitelabel !== 'OrbitInvest' &&
                    whitelabel !== 'GCCBrokers' &&
                    whitelabel !== 'TradiNext' &&
                    whitelabel !== 'TradeCoreUK'
                  "
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/analysis"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    height="19"
                    stroke="currentColor"
                    viewBox="0 0 20 19"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 9L8 6L11 9L15 5M6 18L10 14L14 18M1 1H19M2 1H18V13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V1Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("Analysis") }}
                </router-link>
                <a
                  v-if="whitelabel === 'Blackstone'"
                  class="cursor-pointer group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  @click="openChat()"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  Start LiveChat
                </a>
                <a
                  v-if="
                    (get_profile.profile.type !== 'IB' &&
                      whitelabel === 'ICMVC') ||
                    whitelabel === 'VCGMarkets'
                  "
                  :href="link"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  target="_blank"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("web-terminal") }}
                </a>
                <div>
                  <div v-if="get_profile.profile.type === 'IB'">
                    <div class="my-3">
                      <span
                        class="truncate text-white text-xs uppercase tracking-tighter font-medium ml-2"
                      >
                        {{ $t("Payments") }}
                      </span>
                    </div>
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/ib/request-payment"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 14V4V14ZM8 7H5C4.46957 7 3.96086 7.21071 3.58579 7.58579C3.21071 7.96086 3 8.46957 3 9V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H19C19.5304 20 20.0391 19.7893 20.4142 19.4142C20.7893 19.0391 21 18.5304 21 18V9C21 8.46957 20.7893 7.96086 20.4142 7.58579C20.0391 7.21071 19.5304 7 19 7H16H8ZM15 11L12 14L15 11ZM12 14L9 11L12 14Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{ $t("request-a-payment") }}
                    </router-link>
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/ib/pending-withdrawals"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M18.75 14.9603V18C18.75 18.6896 18.5229 19.3725 18.088 19.8943C17.6498 20.4202 17.023 20.75 16.3333 20.75H4.66667C3.97702 20.75 3.35019 20.4202 2.91198 19.8943C2.47711 19.3725 2.25 18.6896 2.25 18V9C2.25 8.31041 2.47711 7.6275 2.912 7.10564C3.35021 6.57979 3.97702 6.25 4.66667 6.25H11.2205C11.0965 6.73187 11.0225 7.23383 11.0044 7.75H4.66667C4.47227 7.75 4.25124 7.84162 4.06433 8.06592C3.87409 8.2942 3.75 8.62874 3.75 9V18C3.75 18.3712 3.87407 18.7057 4.06434 18.9341C4.25124 19.1584 4.47226 19.25 4.66667 19.25H16.3333C16.5277 19.25 16.7487 19.1584 16.9357 18.9341C17.1259 18.7057 17.25 18.3712 17.25 18V14.9603C17.4964 14.9865 17.7466 15 18 15C18.2534 15 18.5036 14.9865 18.75 14.9603Z"
                          fill="white"
                          fill-rule="evenodd"
                        />
                        <path
                          d="M18 5.77778V8L19.6667 9.66667M23 8C23 8.65661 22.8707 9.30679 22.6194 9.91342C22.3681 10.52 21.9998 11.0712 21.5355 11.5355C21.0712 11.9998 20.52 12.3681 19.9134 12.6194C19.3068 12.8707 18.6566 13 18 13C17.3434 13 16.6932 12.8707 16.0866 12.6194C15.48 12.3681 14.9288 11.9998 14.4645 11.5355C14.0002 11.0712 13.6319 10.52 13.3806 9.91342C13.1293 9.30679 13 8.65661 13 8C13 6.67392 13.5268 5.40215 14.4645 4.46447C15.4021 3.52678 16.6739 3 18 3C19.3261 3 20.5979 3.52678 21.5355 4.46447C22.4732 5.40215 23 6.67392 23 8Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{ $t("pending-withdrawals") }}
                    </router-link>
                    <router-link
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/ib/payments-history"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 9V7C17 6.46957 16.7893 5.96086 16.4142 5.58579C16.0391 5.21071 15.5304 5 15 5H5C4.46957 5 3.96086 5.21071 3.58579 5.58579C3.21071 5.96086 3 6.46957 3 7V13C3 13.5304 3.21071 14.0391 3.58579 14.4142C3.96086 14.7893 4.46957 15 5 15H7M9 19H19C19.5304 19 20.0391 18.7893 20.4142 18.4142C20.7893 18.0391 21 17.5304 21 17V11C21 10.4696 20.7893 9.96086 20.4142 9.58579C20.0391 9.21071 19.5304 9 19 9H9C8.46957 9 7.96086 9.21071 7.58579 9.58579C7.21071 9.96086 7 10.4696 7 11V17C7 17.5304 7.21071 18.0391 7.58579 18.4142C7.96086 18.7893 8.46957 19 9 19ZM16 14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16C13.4696 16 12.9609 15.7893 12.5858 15.4142C12.2107 15.0391 12 14.5304 12 14C12 13.4696 12.2107 12.9609 12.5858 12.5858C12.9609 12.2107 13.4696 12 14 12C14.5304 12 15.0391 12.2107 15.4142 12.5858C15.7893 12.9609 16 13.4696 16 14Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{
                        $t("payment-history-6ecdeeece7dc4ee366197ea2e4b6b778")
                      }}
                    </router-link>
                  </div>
                  <div
                    v-if="
                      (get_profile.state !== 'Demo' &&
                        get_profile.profile.type !== 'IB') ||
                      (whitelabel === 'TDSouthAfrica' &&
                        get_profile.profile.type !== 'IB')
                    "
                  >
                    <div class="my-3">
                      <span
                        class="truncate text-white text-xs uppercase tracking-tighter font-medium ml-2"
                      >
                        {{ $t("Payments") }}
                      </span>
                    </div>
                    <router-link
                      v-if="get_profile.profile.type !== 'IB'"
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/deposit-funds"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M10 7V17V7ZM14 14H17C17.5304 14 18.0391 13.7893 18.4142 13.4142C18.7893 13.0391 19 12.5304 19 12V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21072 1.96086 1 2.46957 1 3V12C1 12.5304 1.21071 13.0391 1.58579 13.4142C1.96086 13.7893 2.46957 14 3 14H6H14ZM7 10L10 7L7 10ZM10 7L13 10L10 7Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{ $t("deposit-funds-e645a4c2bcea65c6fccd26238e52ec6e") }}
                    </router-link>
                    <router-link
                      v-if="showWithdrawLink"
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/withdraw-funds"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M10 11V1V11ZM6 4H3C2.46957 4 1.96086 4.21071 1.58579 4.58579C1.21071 4.96086 1 5.46957 1 6V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H17C17.5304 17 18.0391 16.7893 18.4142 16.4142C18.7893 16.0391 19 15.5304 19 15V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4H14H6ZM13 8L10 11L13 8ZM10 11L7 8L10 11Z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                      </svg>
                      {{
                        $t("withdraw-funds-f08d90c08960cd320a338bb2571ff562")
                      }}
                    </router-link>

                    <router-link
                      v-if="
                        get_profile.profile.type !== 'IB' &&
                        whitelabel !== 'TD365' &&
                        whitelabel !== 'TDSouthAfrica' &&
                        whitelabel !== 'ICMTrader' &&
                        whitelabel !== 'Blackstone' &&
                        whitelabel !== 'OneTradingMarkets' &&
                        whitelabel !== 'VCGMarkets' &&
                        whitelabel !== 'EagleGlobalMarkets' &&
                        whitelabel !== 'OrbitInvest' &&
                        whitelabel !== 'GCCBrokers' &&
                        whitelabel !== 'TradiNext' &&
                        whitelabel !== 'TradeCoreUK' &&
                        whitelabel !== 'ICMMENA' &&
                        whitelabel !== 'ICMCapital' &&
                        whitelabel !== 'AAAFXEU' &&
                        whitelabel !== 'AAAFXGlobal' &&
                        whitelabel !== 'FXViewEU' &&
                        whitelabel !== 'FXViewGlobal'
                      "
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/apply-for-mastercard"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.8889 20H20.5556C21.2038 20 21.8256 19.7407 22.284 19.279C22.7425 18.8174 23 18.1913 23 17.5385V6.46154C23 5.8087 22.7425 5.1826 22.284 4.72097C21.8256 4.25934 21.2038 4 20.5556 4H3.44444C2.79614 4 2.17438 4.25934 1.71597 4.72097C1.25755 5.1826 1 5.8087 1 6.46154V17.5385C1 18.1913 1.25753 18.8174 1.71597 19.279C2.17438 19.7407 2.79614 20 3.44444 20H7.11111H16.8889Z"
                          opacity="0.8"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.5"
                        />
                        <circle
                          cx="9"
                          cy="12"
                          fill="white"
                          opacity="0.8"
                          r="4"
                        />
                        <circle
                          cx="15"
                          cy="12"
                          fill="white"
                          opacity="0.3"
                          r="4"
                        />
                      </svg>
                      {{ $t("apply-for-mastercard") }}
                    </router-link>
                    <router-link
                      v-if="shouldShowInternalTransferLink()"
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/internal-transfer"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                      {{
                        $t("internal-transfer-2f9b036cc57f3d906425010f8e828fb5")
                      }}
                    </router-link>
                    <router-link
                      v-if="get_profile.profile.type !== 'IB'"
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/payment-history"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        />
                      </svg>
                      {{
                        $t("payment-history-6ecdeeece7dc4ee366197ea2e4b6b778")
                      }}
                    </router-link>
                    <router-link
                      v-if="
                        get_profile.profile.type !== 'IB' &&
                        whitelabel !== 'TD365' &&
                        whitelabel !== 'TDSouthAfrica' &&
                        whitelabel !== 'EagleGlobalMarkets' &&
                        whitelabel !== 'VCGMarkets' &&
                        whitelabel !== 'Blackstone' &&
                        whitelabel !== 'TradeCoreUK'
                      "
                      class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                      exact-active-class="active"
                      to="/bank-details"
                    >
                      <svg
                        class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.6994 8.09466L13.2571 4.34239C12.8705 4.11794 12.4387 4.00049 12 4.00049C11.5613 4.00049 11.1295 4.11794 10.7429 4.34239L4.30057 8.09466C4.20975 8.14759 4.13386 8.22617 4.08094 8.32203C4.02802 8.4179 4.00005 8.52749 4 8.63918V9.38171C4 9.54582 4.0602 9.70321 4.16737 9.81925C4.27453 9.9353 4.41988 10.0005 4.57143 10.0005H19.4286C19.5801 10.0005 19.7255 9.9353 19.8326 9.81925C19.9398 9.70321 20 9.54582 20 9.38171V8.63918C19.9999 8.52749 19.972 8.4179 19.9191 8.32203C19.8661 8.22617 19.7902 8.14759 19.6994 8.09466Z"
                        />
                        <rect
                          height="16"
                          rx="0.75"
                          transform="rotate(90 20 17)"
                          width="1.5"
                          x="20"
                          y="17"
                        />
                        <path
                          clip-rule="evenodd"
                          d="M6.25 11.75C6.25 11.3358 6.58579 11 7 11C7.41421 11 7.75 11.3358 7.75 11.75V15.25C7.75 15.6642 7.41421 16 7 16C6.58579 16 6.25 15.6642 6.25 15.25V11.75ZM11.2498 11.75C11.2498 11.3358 11.5856 11 11.9998 11C12.414 11 12.7498 11.3358 12.7498 11.75V15.25C12.7498 15.6642 12.414 16 11.9998 16C11.5856 16 11.2498 15.6642 11.2498 15.25V11.75ZM16.9996 11C16.5854 11 16.2496 11.3358 16.2496 11.75V15.25C16.2496 15.6642 16.5854 16 16.9996 16C17.4138 16 17.7496 15.6642 17.7496 15.25V11.75C17.7496 11.3358 17.4138 11 16.9996 11Z"
                          fill-rule="evenodd"
                        />
                      </svg>
                      {{ $t("bank-details-f1b3e6404a749ae3a1cd615552d0eb0f") }}
                    </router-link>
                  </div>
                </div>

                <div class="my-3">
                  <span
                    class="truncate text-white text-xs uppercase tracking-tighter font-medium ml-2"
                  >
                    {{ $t("documents") }}
                  </span>
                </div>

                <router-link
                  v-if="
                    get_profile.profile.type !== 'IB' &&
                    get_profile.state !== 'Demo'
                  "
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/legal-documents"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </svg>
                  {{ $t("legal-documents-13e272c98e7585c7fa2ffbc2c47f7208") }}
                </router-link>
                <router-link
                  v-if="
                    get_profile.profile.type !== 'IB' &&
                    get_profile.state !== 'Demo' &&
                    whitelabel !== 'TD365'
                  "
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/upload-documents"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    height="20"
                    stroke="currentColor"
                    viewBox="0 0 16 20"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 8V14M8 8L5 11M8 8L11 11M13 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H8.586C8.8512 1.00006 9.10551 1.10545 9.293 1.293L14.707 6.707C14.8946 6.89449 14.9999 7.1488 15 7.414V17C15 17.5304 14.7893 18.0391 14.4142 18.4142C14.0391 18.7893 13.5304 19 13 19Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("upload-documents-7773dfbb1710dccc7b95283d157c8bc6") }}
                </router-link>
                <router-link
                  v-if="get_profile.profile.type === 'IB'"
                  class="group flex items-center px-2 py-2 text-sm leading-5 font-normal text-gray-200 rounded-md hover:text-white focus:outline-none focus:text-white transition ease-in-out duration-150"
                  exact-active-class="active"
                  to="/ib/upload-documents"
                >
                  <svg
                    class="me-3 h-5 w-5 text-gray-200 group-hover:text-gray-200 group-focus:text-gray-200 transition ease-in-out duration-150"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 10V16M12 10L9 13M12 10L15 13M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H12.586C12.8512 3.00006 13.1055 3.10545 13.293 3.293L18.707 8.707C18.8946 8.89449 18.9999 9.1488 19 9.414V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  {{ $t("upload-documents-7773dfbb1710dccc7b95283d157c8bc6") }}
                </router-link>
              </nav>
            </div>
            <div
              v-if="
                whitelabel !== 'TDSouthAfrica' &&
                whitelabel !== 'EagleGlobalMarkets' &&
                whitelabel !== 'TradeCoreUK'
              "
              class="p-2"
            >
              <language-switcher></language-switcher>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Consent modal -->
    <consents
      v-if="
        get_user_data.show_consent_modal &&
        showConsent &&
        whitelabel !== 'VCGMarkets'
      "
      @close="closeModal"
    ></consents>
    <!--    <proof-of-id-modal-->
    <!--      v-if="showProofOfIdModalComputed && this.whitelabel !== 'GCCBrokers'"-->
    <!--      @close="closeProofModal"-->
    <!--    ></proof-of-id-modal>-->
    <status-change-message-modal
      v-if="showStatusChangeMessageModalComputed"
      @close="closeStatusChangeMessageModal"
    ></status-change-message-modal>
    <!--    <limited-state-upgrade-modal-->
    <!--      v-if="showLimitedStateUpgradeModalComputed"-->
    <!--      @close="closeModal"-->
    <!--    />-->
    <proof-of-id-modal
      v-if="$store.getters.get_icm_proof_modal === 'Address Proof'"
      :documents="['Address Proof']"
    ></proof-of-id-modal>
  </section>
</template>
<script>
import LanguageSwitcher from "@/components/LanguageSwitcher";
import Consents from "@/views/documents/legal-documents/Consents";
import { mapGetters, mapMutations } from "vuex";
import Cookie from "js-cookie";

import ProofOfIdModal from "@/views/ProofOfIdModal";
import StatusChangeMessageModal from "@/views/StatusChangeMessageModal";
import AddLiveAccountModal from "@/views/trading-accounts/AddLiveAccountModal.vue";
import AddDemoAccountModal from "@/views/trading-accounts/AddDemoAccountModal.vue";
import LimitedStateUpgradeModal from "@/views/LimitedStateUpgradeModal.vue";

export default {
  name: "Sidebar",
  components: {
    LimitedStateUpgradeModal,
    AddDemoAccountModal,
    AddLiveAccountModal,
    LanguageSwitcher,
    Consents,
    ProofOfIdModal,
    StatusChangeMessageModal,
  },
  data() {
    return {
      logoName: process.env.VUE_APP_WHITELABEL,
      whitelabel: process.env.VUE_APP_WHITELABEL,
      lang: null,
      showConsent: true,
      showAddAccountModal: false,
      showAddDemoAccountModal: false,
      showLimitedStateUpgradeModal: false,
      showProofOfIdModal: false,
      showVerificationModal: false,
      showStatusChangeMessageModal: false,
    };
  },
  props: {
    mobileMenu: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters([
      "get_profile",
      "get_live_backend_accounts",
      "get_show_sidebar",
      "get_user_data",
      "get_uploaded_documents",
    ]),

    showWithdrawLink() {
      const profileState = this.get_profile.state;
      if (this.whitelabel === "VCGMarkets") {
        return profileState === "Full" || profileState === "Limited";
      } else {
        return (
          profileState === "Limited" ||
          profileState === "Full" ||
          profileState === "Pending"
        );
      }
    },

    checkLanguage() {
      this.lang = this.$store.getters.get_lang;
      if (this.lang === "ar" || this.lang === "fa") {
        return "left-0";
      } else {
        return "right-0";
      }
    },
    link() {
      if (this.whitelabel === "VCGMarkets") {
        return "https://trading.vcgmarkets.com/terminal";
      }
      let lang = this.$store.getters.get_lang;
      if (lang === "zhHant" || lang === "zhHans") {
        lang = "zh";
      }
      return (
        "https://metatraderweb.app/trade?version=4&trade_server=ICMCapitalVC-Demo&servers=ICMCapitalVC-Demo,ICMCapitalVC-LIVE,ICMCapitalVC-LIVE2,ICMCapitalVC-LIVE3&startup_mode=create_demo&demo_all_servers=1&lang=" +
        lang +
        "&color_scheme=black_on_white&utm_source=www.icmcapital.com"
      );
    },
    showLimitedStateUpgradeModalComputed() {
      return (this.showLimitedStateUpgradeModal =
        this.$store.getters.get_limited_state_upgrade_modal);
    },
    showProofOfIdModalComputed() {
      return (this.showProofOfIdModal =
        this.$store.getters.get_proof_of_id_modal);
    },
    showStatusChangeMessageModalComputed() {
      return (this.showStatusChangeMessageModal =
        this.$store.getters.get_status_change_message_modal);
    },
  },
  created() {
    this.$store.dispatch("set_uploaded_documents");
    //EGM client requested that we remove consent modal
    if (this.whitelabel === "EagleGlobalMarkets") {
      this.showConsent = false;
    }

    if (Cookie.get("type") === "IB") {
      this.$store.dispatch("ib_profile");
    }
  },

  methods: {
    ...mapMutations(["CHANGE_SHOW_SIDEBAR"]),
    openAddAccountModal() {
      this.$store.commit("CHANGE_SHOW_SIDEBAR", true);
      this.showAddAccountModal = true;
    },
    openAddDemoAccountModal() {
      this.$store.commit("CHANGE_SHOW_SIDEBAR", true);
      this.showAddDemoAccountModal = true;
    },
    closeModal() {
      this.showConsent = false;
      this.showAddAccountModal = false;
      this.showAddDemoAccountModal = false;
      this.showLimitedStateUpgradeModal = false;
    },
    closeProofModal() {
      this.$store.commit("SET_PROOF_OF_ID_MODAL", false);
    },
    closeStatusChangeMessageModal() {
      this.$store.commit("SET_STATUS_CHANGE_MESSAGE_MODAL", false);
    },
    openChat() {
      LiveChatWidget.call("maximize");
    },
    shouldShowInternalTransferLink() {
      const vcgCase =
        this.whitelabel === "VCGMarkets" &&
        this.get_profile.state !== "Limited";
      if (this.whitelabel === "VCGMarkets") {
        return vcgCase;
      } else {
        return (
          this.get_profile.profile.type !== "IB" &&
          this.whitelabel !== "TDSouthAfrica" &&
          this.whitelabel !== "EagleGlobalMarkets" &&
          this.whitelabel !== "OrbitInvest" &&
          this.whitelabel !== "TradeCoreUK"
        );
      }
    },
  },
};
</script>